const { Commons } = require("../utils/commons");
const { RequestUtils } = require("../utils/requestUtils");

async function subscribe(
  momoInfo,
  bouquetName,
  durationInMonth,
  subscriprionInfo
) {
  console.log(
    "subscribeFunction",
    momoInfo,
    bouquetName,
    durationInMonth,
    subscriprionInfo
  );
  const subscriptionResponse = await sendRegistrationRequest(
    momoInfo,
    bouquetName,
    durationInMonth,
    subscriprionInfo
  );
  const parsedResponse = parseSubscriptionResponse(subscriptionResponse);
  console.log(parsedResponse);
  return parsedResponse;
}

async function sendRegistrationRequest(
  momoInfo,
  bouquetName,
  durationInMonth,
  subscriprionInfo
) {
  const requestData = {
    senderPhoneNumber: momoInfo.phoneNumber,
    senderFirstName: momoInfo.firstName,
    senderLastName: momoInfo.lastName,
    bouquetName: bouquetName,
    durationInMonth: durationInMonth,
    subscriberInfo: {
      noCard: subscriprionInfo.noCard,
      phoneNumber: subscriprionInfo.phoneNumber,
    },
  };
  if (!Commons.isLiveEnv()) {
    requestData.saving = "99.99%";
  }
  const response = await RequestUtils.sendRequestTo(
    Commons.serverBaseUrl() + "/subscribe",
    requestData,
    "post"
  );
  return response;
}

function parseSubscriptionResponse(subscriptionResponse) {
  console.log(subscriptionResponse);
  if (subscriptionResponse.status.code === 200) {
    return {
      success: true,
      statusCode: subscriptionResponse.status.code,
      message: "ok",
      subscriptionUuid: subscriptionResponse.response.transactionData.uuid,
    };
  } else if (subscriptionResponse.status.code === 400) {
    return {
      success: false,
      statusCode: subscriptionResponse.status.code,
      message:
        "Désolé, il semblerait que ton nom ou ton numéro ne soit pas correcte. Corrige-les puis réessai 😉",
      subscriptionUuid: null,
    };
  } else {
    return {
      success: false,
      statusCode: subscriptionResponse.status.code,
      message:
        "Oh, 😧 désolé, une erreur s'est produite, nos développeurs s'en occupent, réessai en attendant",
      subscriptionUuid: null,
    };
  }
}

exports.subscribe = subscribe;
