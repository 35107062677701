function saveInvoice(
  config = {
    userUuid: "Aucun",
    userName: "Aucun",
    userPhoneNumber: "Aucun",
  }
) {
  return window.chipdeals_app_downloadInvoice(
    config.userUuid || "Aucun",
    config.userName || "Aucun",
    config.userPhoneNumber || "Aucun"
  );
}
export default {
  install: (app) => {
    app.config.globalProperties.saveInvoice = saveInvoice;
  },
};
