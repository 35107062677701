// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyDPWw_BrVrwaZgzF1ZB44WLjt8EjB_nuaw",
  authDomain: "mytiviplus-web-site.firebaseapp.com",
  projectId: "mytiviplus-web-site",
  storageBucket: "mytiviplus-web-site.appspot.com",
  messagingSenderId: "892213868965",
  appId: "1:892213868965:web:b426dd0b887a3b45fd104c",
  measurementId: "G-C2QE433GH2"
};
initializeApp(firebaseConfig);

import { getMessaging, getToken } from "firebase/messaging";

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
function requestWebNotification() {
  const messaging = getMessaging();
  return getToken(messaging, {
    vapidKey:
      "BHr_r7rLq2P5Ir4QYi8z1EP3Et-xs2xVkmwHCKaN1OhZV89_UjAwKvTAlcw4gHM9HAqWVIxqEDpdcsjZHKr-VVo",
  })
    .then((currentToken) => {
      if (currentToken) {
        //console.log(currentToken);
        return {
          success: true,
          token: currentToken,
          message: "",
        };
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return {
          success: false,
          token: null,
          message:
            "No registration token available. Request permission to generate one.",
        };
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      return {
        success: false,
        token: null,
        message: "An error occurred while retrieving token.",
      };
    });
}

export default {
  install: (app) => {
    app.config.globalProperties.requestPushNotif = requestWebNotification;
  },
};
