const { subscribe } = require("./use-cases/subscribe.js");
const { getSubscription } = require("./use-cases/getSubscription.js");
const { saveDeviceToken } = require("./use-cases/saveDeviceToken.js");

class Controller {
  static async subscribe(
    momoInfo,
    bouquetName,
    durationInMonth,
    subscriprionInfo
  ) {
    return subscribe(momoInfo, bouquetName, durationInMonth, subscriprionInfo);
  }
  static getSubscription(subscriptionUuid) {
    return getSubscription(subscriptionUuid);
  }
  static saveDeviceToken(deviceToken,subscriptionUuid) {
   return saveDeviceToken(deviceToken,subscriptionUuid)
  }
  static getBouquets() {
    return [
      {
        name: "access",
        description: "245 chaînes, radios et services",
        haveAdultOption: false,
        haveCanalPlus: false,
        haveMyCanal: true,
        tagline:
          "Des séries et du divertissement avec Novelas TV et A+, du sport avec CANAL+ SPORT 1 et des chaines pour toute la famille !",
        uuid: "3158271f-4b3e-4e78-b466-a96ae1f81dce",
        country: {
          countryCode: "BJ",
          countryName: "Bénin",
        },
        price: {
          amount: 5000,
          currency: {
            code: "XOF",
            name: "West African CFA franc",
            symbol: "Fr",
          },
          unit: "month",
        },
        bouquetIllustrationUrl:
          "/images/bouquet/illustration/7065c251258938d8345ca30cc21c39024a92b9af.jpg",
      },
      {
        name: "évasion",
        description: "286 chaînes, radios et services",
        haveAdultOption: false,
        haveCanalPlus: false,
        haveMyCanal: true,
        tagline:
          "Le meilleur du divertissement pour toute la famille avec Novelas TV, Nollywood TV ou National Geographic ou Disney Channel et la LIGUE 1 UBER EATS pour les fans de sport !",
        uuid: "f580ab7c-ff2d-4073-9c8f-08213dfd827c",
        country: {
          countryCode: "BJ",
          countryName: "Bénin",
        },
        price: {
          amount: 10000,
          currency: {
            code: "XOF",
            name: "West African CFA franc",
            symbol: "Fr",
          },
          unit: "month",
        },
        bouquetIllustrationUrl:
          "/images/bouquet/illustration/bae6b86045033358f6aa86fdc5fbc49c349932fe.jpg",
      },
      {
        name: "essentiel+",
        description: "204 chaînes, radios et services",
        haveAdultOption: false,
        haveCanalPlus: true,
        haveMyCanal: true,
        tagline:
          "La formule pour les mordus de sport : UEFA CHAMPIONS LEAGUE, PREMIER LEAGUE OU ENCORE NBA ET FORMULE 1 !",
        uuid: "e38f4d0a-e6f5-4de6-874c-46842c29b0aa",
        country: {
          countryCode: "BJ",
          countryName: "Bénin",
        },
        price: {
          amount: 12000,
          currency: {
            code: "XOF",
            name: "West African CFA franc",
            symbol: "Fr",
          },
          unit: "month",
        },
        bouquetIllustrationUrl:
          "/images/bouquet/illustration/db070423a954f537810a93a722c25cda46b34988.jpg",
      },
      {
        name: "access+",
        description: "237 chaînes, radios et services",
        haveAdultOption: false,
        haveCanalPlus: true,
        haveMyCanal: true,
        tagline:
          "Des séries et du divertissement avec Novelas TV et A+ et toutes les chaines CANAL+ pour profiter du meilleur du sport !",
        uuid: "24ccda27-fc50-45cf-88b3-ef91e85f7346",
        country: {
          countryCode: "BJ",
          countryName: "Bénin",
        },
        price: {
          amount: 15000,
          currency: {
            code: "XOF",
            name: "West African CFA franc",
            symbol: "Fr",
          },
          unit: "month",
        },
        bouquetIllustrationUrl:
          "/images/bouquet/illustration/9fcae8f1807e146dcbd9d3993f74e0eefd88d076.jpg",
      },
      {
        name: "evasion +",
        description: "310 chaînes, radios et services",
        haveAdultOption: false,
        haveCanalPlus: true,
        haveMyCanal: true,
        tagline:
          "La formule la plus complète avec le meilleur du divertissement, du sport et toutes les chaines CANAL+ !",
        uuid: "88c2bf51-4cf1-4815-9d9d-5dfb3a23320b",
        country: {
          countryCode: "BJ",
          countryName: "Bénin",
        },
        price: {
          amount: 20000,
          currency: {
            code: "XOF",
            name: "West African CFA franc",
            symbol: "Fr",
          },
          unit: "month",
        },
        bouquetIllustrationUrl:
          "/images/bouquet/illustration/f3a0f900c3e8e8756c4544a7fc348db749ef879e.jpg",
      },
      {
        name: "tout canal+",
        description: "328 chaînes, radios et services",
        haveAdultOption: false,
        haveCanalPlus: true,
        haveMyCanal: true,
        tagline:
          "Entrez dans un monde de privilèges avec l'intégralité des chaines et le pack VIP pour des services exclusifs !",
        uuid: "203a8fee-4b62-4401-8cf3-23c13a2a0a32",
        country: {
          countryCode: "BJ",
          countryName: "Bénin",
        },
        price: {
          amount: 40000,
          currency: {
            code: "XOF",
            name: "West African CFA franc",
            symbol: "Fr",
          },
          unit: "month",
        },
        bouquetIllustrationUrl:
          "/images/bouquet/illustration/d8f1927d9edc24d07e2abca022b59e6b07e6c2e9.jpg",
      },
    ];
  }
}

exports.Controller = Controller;
