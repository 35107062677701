import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap";
import router from "./router";
//import VueHighlightJS from "vue3-highlightjs";
//import "highlight.js/styles/solarized-dark.css";
import "animate.css";
import VueCookies from "vue3-cookies";
import toaststrap from "./plugins/toaststrap/config";
import invoice from "./plugins/invoice/config";
import pushNotifRequester from "./plugins/push/index.js";

const { Commons } = require("./assets/js/utils/commons");
const { AnalyticsUtils } = require("./assets/js/utils/analyticsUtils");
const { Controller } = require("./assets/js/controller");

const app = createApp(App);
app.config.globalProperties.$Commons = Commons;
app.config.globalProperties.$AnalyticsUtils = AnalyticsUtils;
app.config.globalProperties.$Controller = Controller;
app.config.globalProperties.$GlobalVars = { showDownloadFooter: true };
app.use(router);
//app.use(VueHighlightJS);
app.use(VueCookies);
app.use(toaststrap);
app.use(invoice);
app.use(pushNotifRequester);
app.mount("#app");
