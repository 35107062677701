<template>
  <section class="hero is-black is-medium" id="footer" v-if="false">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-4">
            <div class="is-size-6 mb-2">Télécharger l'application</div>
            <p>
              <a
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.djamo.app"
              >
                <picture style="width: 200px" alt="Disponible sur Google Play">
                  <source
                    srcset="
                      https://www.djamo.ci/assets/images/google-play-20200810@2x.webp 2x,
                      https://www.djamo.ci/assets/images/google-play-20200810.webp    1x
                    "
                    type="image/webp"
                  />
                  <source
                    srcset="
                      https://www.djamo.ci/assets/images/google-play-20200810@2x.png 2x,
                      https://www.djamo.ci/assets/images/google-play-20200810.png    1x
                    "
                    type="image/png"
                  />
                  <img
                    src="https://www.djamo.ci/assets/images/google-play-20200810.png"
                    alt="Disponible sur Google Play"
                  />
                </picture>
              </a>
            </p>
            <p>
              <a
                rel="noreferrer"
                href="https://apps.apple.com/app/id1504072402"
              >
                <picture
                  style="width: 200px"
                  alt="Disponible sur Apple App Store"
                >
                  <source
                    srcset="
                      https://www.djamo.ci/assets/images/apple-ios-20200810@2x.webp 2x,
                      https://www.djamo.ci/assets/images/apple-ios-20200810.webp    1x
                    "
                    type="image/webp"
                  />
                  <source
                    srcset="
                      https://www.djamo.ci/assets/images/apple-ios-20200810@2x.png 2x,
                      https://www.djamo.ci/assets/images/apple-ios-20200810.png    1x
                    "
                    type="image/png"
                  />
                  <img
                    src="https://www.djamo.ci/assets/images/google-play-20200810.png"
                    alt="Disponible sur Apple App Store"
                  />
                </picture>
              </a>
            </p>
          </div>
          <div class="column is-2">
            <div class="is-size-6">Société</div>
            <p>
              <a class="navbar-item" style="padding-left: 0" href="/ci/a-propos"
                >A PROPOS</a
              >
            </p>
            <p>
              <a
                class="navbar-item"
                style="padding-left: 0"
                href="/ci/apprendre"
                >APPRENDRE</a
              >
            </p>
            <p>
              <a
                class="navbar-item"
                style="padding-left: 0"
                rel="noreferrer"
                href="https://djamo.breezy.hr"
                target="_blank"
                >CARRIERES</a
              >
            </p>
          </div>
          <div class="column is-5">
            <div class="is-size-6 mb-2">Nous contacter</div>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/djamoci"
              class="mr-4"
              target="_blank"
            >
              <img
                src="https://www.djamo.ci/assets/icons/instagram_20200727.svg"
                width="30"
                alt="Instagram"
              />
            </a>
            <a
              rel="noreferrer"
              href="https://www.facebook.com/djamoci"
              class="mr-4"
              target="_blank"
            >
              <img
                src="https://www.djamo.ci/assets/icons/facebook_20200727.svg"
                width="18"
                alt="Facebook"
              />
            </a>
            <a
              rel="noreferrer"
              href="https://www.twitter.com/djamoci"
              class="mr-4"
              target="_blank"
            >
              <img
                src="https://www.djamo.ci/assets/icons/twitter_20200727.svg"
                width="30"
                alt="Twitter"
              />
            </a>
            <p class="is-size-7 mt-4 mb-2 has-text-justified">
              Si vous souhaitez en savoir plus sur l'entité DJAMO qui vous
              propose ces services ou si vous avez d'autres questions, veuillez
              nous contacter via le chat de l'application dans l'application
              Djamo ou par email à l'adresse
              <a
                href="mailto:support@djamo.ci"
                style="color: #fff; text-decoration: underline"
                >support@djamo.ci</a
              >.
            </p>
            <p class="is-size-7 mt-4 mb-2 has-text-justified">
              Ce site est publié conjointement par DJAMO Côte d'Ivoire et DJAMO
              Holding Ltd. La carte VISA DJAMO est une carte VISA co-brandée
              émise par notre partenaire BGFIBank Côte d'Ivoire.
            </p>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-centered mt-6">
            © Djamo Côte d'Ivoire -
            <a href="/ci/conditions-generales-et-tarifaires" style="color: #fff"
              >Termes et conditions</a
            >
            -
            <a href="/ci/politique-de-confidentialite" style="color: #fff"
              >Politique de confidentialité</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer
    v-if="false"
    class="footer-1 space--xs text-center-xs pt-5 pb-3"
    style="margin-top: 0px"
  >
    <!-- <div class="container">
      <div class="row row-cols-2 row-cols-sm-4 row-cols-md-6">
        <div class="col">
          <h3 class="mt-1">
            <router-link
              to="/"
              class="navbar-brand logo zoomHover d-block w-100"
              ><img src="../../public/logo.png" /> SmallKash
            </router-link>
          </h3>
          <p>
            First mobile Top-up API made for developers. Enable your apps and
            websites to send mobile top-up worldwide.
          </p>
        </div>
        <div
          class="col"
          v-for="(footerSession, sessionKey) in footerSessions"
          :key="sessionKey"
        >
          <h3 class="mt-1">{{ footerSession.name }}</h3>
          <ul>
            <li
              class="sessionItem zoomHover"
              v-for="(sessionItem, itemKey) in footerSession.items"
              :key="itemKey"
            >
              <router-link class="" :to="sessionItem.path">
                {{ sessionItem.name }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div> -->
    <div class="down-footer">
      <div class="col-12 text-center">
        <br /><span class="type--fine-print white"
          >Copyright © <span class="update-year">2021</span> Chipdeals Inc.
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      footerSessions: [
        {
          name: "Use cases",
          items: [
            { name: "Send money", path: "#" },
            { name: "Ask money", path: "#" },
          ],
        },
        {
          name: "Resources",
          items: [
            { name: "Support", path: "#" },
            { name: "Developers", path: "#" },
            { name: "GitHub", path: "#" },
            { name: "Blog", path: "#" },
            { name: "Customer stories", path: "#" },
            { name: "Promotions", path: "#" },
          ],
        },
        {
          name: "Company",
          items: [
            { name: "About us", path: "#" },
            { name: "Contact us", path: "#" },
            { name: "Careers", path: "#" },
            { name: "Pricing", path: "#" },
            { name: "Media Kit", path: "#" },
          ],
        },
        {
          name: "Legal",
          items: [
            { name: "Terms of use", path: "#" },
            { name: "Privacy policy", path: "#" },
            { name: "Security information", path: "#" },
            { name: "Cookie policy", path: "#" },
            { name: "User agreement", path: "#" },
          ],
        },
        {
          name: "Social",
          items: [
            { name: "Twitter", path: "#" },
            { name: "Instagram", path: "#" },
            { name: "Facebook", path: "#" },
            { name: "GitHub", path: "#" },
          ],
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  color: #eee !important;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: block;
}
a,
a:hover {
  color: var(--color1);
  text-decoration: none;
}
.logo,
.logo:hover {
  width: 100%;
  max-width: 25px;
  color: var(--color3) !important;
}
.sessionItem {
  font-weight: 100;
  font-size: 14px;
}
</style>
