<template>
  <div class="overlayer">
    <div class="clickClose w-100 h-100" @click="closeSubscriptionModal"></div>
    <div class="subscriptionModal mx-auto h-100">
      <!--FormCard
        v-if="cardPaidSuccessfully"
        cardTitle="L'abonnement a été lancé avec succès"
        :cardSubtitle="successCardSubtitle"
        :onClickContinueBtn="enableNotification"
        :activateNextButton="true"
        :onClickCancelBtn="closeSubscriptionModal"
        buttonText="Ok"
        class="w-100 mx-auto animate__animated animate__bounceIn"
        :style="{ top: '20%' }"
      >
        <div class="animate__animated animate__flip succesPic">
          <img src="@/assets/pictures/green_check.png" alt="success" class="fill_pic" />
        </div>
      </FormCard-->
      <FormCard
        cardTitle="Abonnement Canal+ moins cher"
        :cardSubtitle="subtitle"
        :onClickContinueBtn="onClickNext"
        :activateNextButton="dataAreCorrect && !isPaying"
        :onClickCancelBtn="closeSubscriptionModal"
        :buttonText="subscriptionText"
        class="w-100 mx-auto"
        :style="{ top: '20%' }"
      >
        <form v-if="currentFieldsFillingStep === 'subscription'">
          <InputField
            name="noCard"
            :updateValue="
              (value) => {
                noCard = value;
              }
            "
            placeholder="12345678912345"
            fieldType="text"
            :defaultValue="noCard"
            label="Numéro de carte du décodeur"
            :disabled="isPaying"
          />
          <InputField
            name="subscriberPhoneNumber"
            :updateValue="
              (value) => {
                subscriberPhoneNumber = value;
              }
            "
            placeholder="51236547"
            fieldType="tel"
            :defaultValue="subscriberPhoneNumber"
            label="Numéro de téléphone Canal+"
            :disabled="isPaying"
          />

          <InputField
            name="duration"
            label="Durée de l'abonnement"
            :disabled="isPaying"
          >
            <select
              class="form-select"
              aria-label="duration"
              v-model="duration"
            >
              <option
                v-for="(month, index) in [1, 2, 3, 6, 12]"
                :value="month"
                :key="index"
              >
                {{ month }} Mois
              </option>
            </select>
          </InputField>

          <InputField
            name="bouquetName"
            label="Bouquet Canal +"
            :disabled="isPaying"
          >
            <select
              class="form-select"
              aria-label="bouquetName"
              v-model="bouquetName"
            >
              <option
                v-for="(bouquet, index) in bouquets"
                :value="bouquet.name"
                :key="index"
              >
                {{ bouquet.price.amount }} F : {{ bouquet.name }}
              </option>
            </select>
          </InputField>
        </form>
        <form v-else>
          <InputField
            name="momoLastName"
            :updateValue="
              (value) => {
                momoLastName = value;
              }
            "
            placeholder="BOUKE"
            fieldType="text"
            :defaultValue="momoLastName"
            label="Nom du compte Momo"
            :disabled="isPaying"
          />
          <InputField
            name="momoFirstName"
            :updateValue="
              (value) => {
                momoFirstName = value;
              }
            "
            placeholder="Lucie"
            fieldType="text"
            :defaultValue="momoFirstName"
            label="Prénom du compte Momo"
            :disabled="isPaying"
          />
          <InputField
            name="momoPhoneNumber"
            :updateValue="
              (value) => {
                momoPhoneNumber = value;
              }
            "
            placeholder="51236547"
            fieldType="tel"
            :defaultValue="momoPhoneNumber"
            label="Numéro de téléphone Momo"
            :disabled="isPaying"
          />
        </form>
      </FormCard>
    </div>
  </div>
</template>

<script>
import FormCard from "@/components/FormCard.vue";
import InputField from "@/components/InputField.vue";
//import { Controller } from "../assets/js/controller";
//import { Commons } from "../assets/js/utils/commons";

export default {
  name: "SubscriptionModal",
  props: {
    closeSubscriptionModal: Function,
  },
  components: { FormCard, InputField },
  data() {
    return {
      cardPaidSuccessfully: false,
      isPaying: false,
      waitingSubscription: false,
      successCardSubtitle: "",
      subtitle: "Entrez les informations d'identification de votre décodeur",
      subscriptionText: "Continuer ›",
      step: 0,
      expiry: "",
      email: "lol",
      phoneNumber: "",
      referralCode: this.$route.params.referralCode || "",
      name: "",
      successText: "Votre abonnement viens d'être activé. Merci.",
      validateUssdText:
        "Le paiement est lancé, confirmez en validant le code sur ton portable",
      subscriptionPendingText:
        "Le paiement a été validé. Votre abonnement est en cours d'activation. Activez la notification pour rester informé.",
      bouquets: [],
      noCard: "",
      subscriberPhoneNumber: "",
      duration: 1,
      bouquetName: "access",
      momoFirstName: "",
      momoLastName: "",
      momoPhoneNumber: "",
      currentFieldsFillingStep: "subscription",
      subscriptionFieldsAreValid: false,
      momoFieldsAreValid: false,
      dataAreCorrect: false,
      price: 0,
      savingString: "1%",
    };
  },
  async mounted() {
    this.bouquets = await this.$Controller.getBouquets();
    this.computePrice();
  },
  methods: {
    onClickNext() {
      if (!this.dataAreCorrect) {
        this.notify({
          text: "Remplissez tout les champ pour que nous activons votre abonnement",
          duration: 5000,
          progress: false,
          type: "WARNING",
        });
        return;
      }
      if (this.isPaying) return;
      if (this.currentFieldsFillingStep == "subscription") {
        this.currentFieldsFillingStep = "momo";
        this.subtitle = "Entrez les informations de votre compte Momo";
        this.subscriptionText = "Payer " + this.price + " f ›";
      } else {
        this.subscribe();
      }
    },
    computePrice() {
      const bouquet = this.bouquets.find(
        (bouquetToCheck) => bouquetToCheck.name === this.bouquetName
      );
      if (this.$Commons.isEmpty(bouquet)) return;
      const saving = parseFloat(this.savingString.replace(/,/g, "."));
      const subscriptionPrice = bouquet.price.amount * this.duration;
      const userEconomy = Math.ceil((subscriptionPrice * saving) / 100);
      const senderAmount = subscriptionPrice - userEconomy;
      this.price = senderAmount;

      if (this.price !== 0) {
        this.subscriptionText = "Total: " + this.price + " f ›";
      }
    },
    async subscribe() {
      this.$AnalyticsUtils.catchEvent("try Subscription", {});
      this.isPaying = true;
      let notificationId = this.notify({
        text: "chargement en cours . . .",
        duration: 10000,
      });

      const timeoutId = setTimeout(() => {
        notificationId = this.notify({
          text: "C'est plus long que prévu . . .",
          duration: 30000,
        });
      }, 10000);

      const momoInfo = {
        phoneNumber: this.momoPhoneNumber,
        firstName: this.momoFirstName,
        lastName: this.momoLastName,
      };
      const subscriprionInfo = {
        noCard: this.noCard,
        phoneNumber: this.subscriberPhoneNumber,
      };
      const subscriptionResponse = await this.$Controller.subscribe(
        momoInfo,
        this.bouquetName,
        this.duration,
        subscriprionInfo
      );

      clearTimeout(timeoutId);
      this.destroyNotification(notificationId);
      console.log(subscriptionResponse);

      if (subscriptionResponse.success) {
        notificationId = this.notify({
          text: this.validateUssdText,
          duration: 300000,
          progress: false,
          type: "INFO",
        });
        this.checkSubscriptionStatus(
          subscriptionResponse.subscriptionUuid,
          notificationId
        );
        //this.closeSubscriptionModal();
      } else {
        this.$AnalyticsUtils.catchEvent("payment failed", {
          reason: subscriptionResponse.messag,
        });

        this.notify({
          text: subscriptionResponse.message,
          duration: 4000,
          progress: false,
          type: "WARNING",
        });
        this.errorMessage = subscriptionResponse.message;
        this.isPaying = false;
        if (subscriptionResponse.statusCode === 409) {
          this.closeSubscriptionModal();
        }
      }
    },
    checkFieldsFilling() {
      const noCardIsValid = /^\d{14}$/.test(this.noCard);
      const subscriberPhoneNumberIsValid = /^\d{8}$/.test(
        this.subscriberPhoneNumber
      );
      const momoFirstNameIsValid = !this.$Commons.isEmpty(this.momoFirstName);
      const momoLastNameIsValid = !this.$Commons.isEmpty(this.momoLastName);
      const momoPhoneNumberIsValid = /^\d{8}$/.test(this.momoPhoneNumber);
      this.subscriptionFieldsAreValid =
        noCardIsValid && subscriberPhoneNumberIsValid;
      this.momoFieldsAreValid =
        momoFirstNameIsValid && momoLastNameIsValid && momoPhoneNumberIsValid;

      if (this.currentFieldsFillingStep == "subscription") {
        this.dataAreCorrect = this.subscriptionFieldsAreValid;
      } else {
        this.dataAreCorrect = this.momoFieldsAreValid;
      }
    },
    checkSubscriptionStatus(subscriptionUuid, currentNotificationId) {
      setTimeout(async () => {
        const subscriptionResponse = await this.$Controller.getSubscription(
          subscriptionUuid
        );
        console.log(subscriptionResponse);
        if (subscriptionResponse.success) {
          if (subscriptionResponse.subscription.statusMessageCode === 200) {
            this.$AnalyticsUtils.catchEvent("payment success", {});
            this.destroyNotification(currentNotificationId);
            this.notify({
              text: this.successText,
              duration: 10000,
              progress: false,
              type: "SUCCESS",
            });
            this.isPaying = false;
            this.cardPaidSuccessfully = true;
            this.closeSubscriptionModal();
            /*  this.downloadInvoice(
              subscriptionResponse.subscription.uuid,
              this.name,
              this.phoneNumber
            ); */
          } else if (subscriptionResponse.subscription.status === "error") {
            this.$AnalyticsUtils.catchEvent("payment failed", {
              reason: subscriptionResponse.subscription.message,
            });

            this.destroyNotification(currentNotificationId);
            this.notify({
              text: subscriptionResponse.subscription.message,
              duration: 5000,
              progress: false,
              type: "WARNING",
            });
            this.isPaying = false;
          } else {
            let notificationId = currentNotificationId;
            if (
              !this.waitingSubscription &&
              subscriptionResponse.subscription.statusMessageCode === 230
            ) {
              this.destroyNotification(currentNotificationId);
              this.waitingSubscription = true;
              notificationId = this.notify({
                text: this.subscriptionPendingText,
                duration: 300000,
                progress: false,
                type: "INFO",
              });
              setTimeout(()=>{
                this.enableNotification(subscriptionUuid)
              },3000)
            }
            this.checkSubscriptionStatus(subscriptionUuid, notificationId);
          }
        } else {
          this.$AnalyticsUtils.catchEvent("payment error", {
            reason: subscriptionResponse.message,
          });
          this.destroyNotification(currentNotificationId);
          this.notify({
            text: subscriptionResponse.message,
            duration: 4000,
            progress: false,
            type: "WARNING",
          });
          this.isPaying = false;
          this.errorMessage = subscriptionResponse.message;
        }
      }, 1000);
    },
    async enableNotification(subscriptionUuid) {
      this.closeSubscriptionModal();
      const pushRes = await this.requestPushNotif();
      //console.log(pushRes);
      if (pushRes.success) {
        this.$Controller.saveDeviceToken(pushRes.token,subscriptionUuid);
      }
    },
  },
  watch: {
    noCard() {
      this.checkFieldsFilling();
    },
    subscriberPhoneNumber() {
      this.checkFieldsFilling();
    },
    duration() {
      this.checkFieldsFilling();
      this.computePrice();
    },
    bouquetName() {
      this.checkFieldsFilling();
      this.computePrice();
    },
    momoFirstName() {
      this.checkFieldsFilling();
    },
    momoLastName() {
      this.checkFieldsFilling();
    },
    momoPhoneNumber() {
      this.checkFieldsFilling();
    },
    currentFieldsFillingStep() {
      this.checkFieldsFilling();
    },
  },
};
</script>

<style scoped>
.overlayer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100000000;
  background: #212121cc;
}
.clickClose {
  position: absolute;
  top: 0;
  left: 0%;
  cursor: pointer;
}
.subscriptionModal {
  width: 260px !important;
}
.succesPic {
  animation-delay: 5s;
}
</style>
