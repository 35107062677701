<template>
  <SubscriptionModal
    v-if="showSubscriptionModal"
    :closeSubscriptionModal="closeSubscriptionModal"
  />
  <div class="container-fluid p-0" style="position: relative">
    <Header />
    <div class="mobileViewd">
      <router-view />
    </div>
    <Footer />
    <DownloadingAppFooter  />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import SubscriptionModal from "./components/SubscriptionModal.vue";
import DownloadingAppFooter from "./components/DownloadingAppFooter.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    SubscriptionModal,DownloadingAppFooter
  },
  data() {
    return {
      showSubscriptionModal: false,
    };
  },
  mounted() {
    this.$Commons.initGlobalVariables();
    const isFirstVisit = this.$Commons.isEmpty(
      this.$cookies.get("smallkash.isFirstVisit")
    );
    this.$AnalyticsUtils.initAnalytics(isFirstVisit);
    this.$cookies.set("smallkash.isFirstVisit", true);

    const catchAnalyticsEvent = this.$AnalyticsUtils.catchEvent;
    //const subscribe = this.subscribe;
    document.addEventListener("click", function (event) {
      let ctaTag;
      let ctaLinkClicked = false;
      event.composedPath().map((element) => {
        if (/ctaBen/i.test(element.className)) {
          ctaTag = element;
          ctaLinkClicked = true;
        }
        return null;
      });
      if (ctaLinkClicked) {
        //event.preventDefault();
        //subscribe();
        catchAnalyticsEvent("cta button clicked", {
          buttonText: ctaTag.innerText,
        });
      }
    });
  },
  methods: {
    subscribe: function () {
      console.log("registration modal");
      this.showSubscriptionModal = true;
    },
    closeSubscriptionModal: function () {
      this.showSubscriptionModal = false;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;900&display=swap");
@font-face {
  font-family: "circular-bold";
  src: url("./assets/font/circular_std_bold.otf");
  font-display: swap !important;
}

@font-face {
  font-family: "circular-medium";
  src: url("./assets/font/circular_std_medium.otf");
  font-display: swap !important;
}

.harlow {
  font-family: "myFirstFont";
}

:root {
  --colorRed1: #ff004d;
  --colorGray2: #ffe5ed;
  --color1: #395174;
  --color2: #627796;
  --color3: #1030ff;
  --color4: #481985;
  --color5: #0b3641;
  --white1: white;
  --white2: #faf8fb;
  --white3: #d1eff7;
  --white4: #abb7bb;
  --white5: #f0f4fd;
  --white6: #e9f7fb;
}

.wa-chat-box-poweredby {
  display: none;
}

html,
body {
  min-height: max-content;
  background-color: var(--white1) !important;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.mobileView {
  max-width: 500px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

#app * {
  color: var(--color2);
  font-family: "circular-medium", "Work Sans" !important;
  font-display: swap !important;
}

.blackText {
  color: black !important;
}

.bold {
  font-weight: 900 !important;
  font-family: "Work Sans" !important;
}

.bold2 {
  font-family: "circular-bold" !important;
}

.color1 {
  color: var(--color1) !important;
}
.color2 {
  color: var(--color2) !important;
}
.color3 {
  color: var(--color3) !important;
}
.color4 {
  color: var(--color4) !important;
}
.colorRed1 {
  color: var(--colorRed1) !important;
}

.customedBtn {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0.65em !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  padding-right: 2rem;
  padding-left: 2rem;
  font-weight: 900 !important;
  font-family: "circular-bold" "Work Sans" !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

.underlineDotted {
  text-decoration: underline dashed;
}

.noShadow {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.bg-color3 {
  background-color: var(--color3) !important;
}

.bg-white3 {
  background-color: var(--white3) !important;
}

.bg-white5 {
  background-color: var(--white5) !important;
}


.bg-gray2 {
  background-color: var(--colorGray2) !important;
}

.cursorPointer,
.cursorPointer > *,
.zoomHover,
.zoomHover > * {
  cursor: pointer !important;
}
.zoomHover:hover {
  transform: scale(1.04);
}

a,
a:hover {
  color: var(--color1) !important;
}
.cardSession {
  box-shadow: var(--white4) -6px 14px 20px 1px;
  border-radius: 0.7em;
}

.ctaBtn {
  top: 0;
  left: 0;
  transition: all 0.15s linear 0s;
  position: relative;
  display: inline-block;
  padding: 0.75rem 1.25rem 0.95rem;
  background-color: var(--colorRed1);
  border-radius: 6px;
  color: #fff !important;
  font-size: 1rem;
  line-height: 1.125rem;
  box-shadow: 5px 5px 0 #91012c;
  text-decoration: none;
  border-width: 2px;
  border-color: var(--colorRed1);
  border-style: solid;
  cursor: pointer;
}
.ctaBtn:hover {
  top: 5px;
  left: 4px;
  box-shadow: 0 0 0 #91012c;
  background-color: #91012c;
  border-color: #91012c;
  color: #fff !important;
  text-decoration: none;
}
.ctaBtn.disabled:hover {
  top: 0;
  left: 0;
  box-shadow: 5px 5px 0 #91012c;
  background-color: var(--colorRed1);
  border-color: var(--colorRed1);
  color: #fff !important;
  text-decoration: none;
}
.disabled{
  opacity:0.3;
}

img {
  background-image: linear-gradient(
    to right,
    #ebebeb calc(50% - 100px),
    #c5c5c5 50%,
    #ebebeb calc(50% + 100px)
  );
  background-size: 0;
  position: relative;
  overflow: hidden;
}

img.fill_pic {
  width: 100%;
}

img::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: calc(200% + 200px);
  bottom: 0;
  background-image: inherit;
  animation: move 2s linear infinite;
}

@keyframes move {
  to {
    transform: translateX(calc(50% + 100px));
  }
}
.animate__animated {
  animation-duration: 1s;
}

.floatAnim {
  animation: float 3s infinite;
}

.floatAnimNoBg {
  animation: floatNoBg 3s infinite;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

@keyframes floatNoBg {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes reveal {
  0% {
    transform: translate3d(0, 30px, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes scaleCircle {
  0% {
    opacity: 1;
    transform: scale(0.8);
  }
  to {
    opacity: 0;
    transform: scale(1.3);
  }
}
@keyframes scaleCircle2 {
  0% {
    opacity: 1;
    transform: scale(0.8);
  }
  to {
    opacity: 0;
    transform: scale(4.9);
  }
}
</style>
