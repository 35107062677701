<template>
  <footer
    class="d-md-none smallText py-4 mt-4"
    vv-show="!alreadyGetApp && showDownloadFooter"
  >
    <div
      class="container-fluid fixed-bottom bg-white3 py-3 animate__animated animate__fadeInUp"
      :class="{ animate__fadeOutDown: !showDownloadFooter }"
    >
      <div class="row justify-content-between">
        <div class="col-3">
          <div class="d-inline-block align-middle">
            <img class="appLogo ms-1" src="../../public/logo.png" /> <br />
            MyTivi+
          </div>
          <div class="d-inline-block align-middle"></div>
        </div>
        <div class="col text-end">
          <a
            href="#"
            class="customedBtn ctaBtn zoomHover cta-link d-inline-flex"
            @click="getApp()"
          >
            <div class="col-10 text-white bold">
              Installez Mytivi+ <br />
              <svg width="79" height="13" viewBox="0 0 79 13" fill="none">
                <g clip-path="url(#clip0_newJay)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.438 4.75429C12.3757 4.5629 12.2065 4.43906 12.0071 4.43906L7.93101 4.42874L6.64576 0.615086C6.58162 0.424637 6.41382 0.296577 6.21115 0.304552C6.01174 0.306897 5.84347 0.431674 5.78351 0.623529L4.58007 4.42874L0.454722 4.43906C0.255311 4.43906 0.0861138 4.5629 0.0238269 4.75382C-0.0389249 4.9452 0.0247566 5.14644 0.185587 5.26559L3.41382 7.66777L2.14763 11.7052C2.0872 11.897 2.15227 12.0969 2.31403 12.2151C2.39538 12.2742 2.48834 12.3042 2.58131 12.3042C2.67335 12.3042 2.76492 12.2751 2.8444 12.2174L6.24787 9.79694L9.61741 12.2155C9.77777 12.3338 9.98788 12.3333 10.1492 12.2151C10.3109 12.0973 10.376 11.8975 10.316 11.7056L9.04799 7.66777L12.2758 5.26606C12.4366 5.14644 12.5003 4.9452 12.438 4.75429Z"
                    fill="#FFC83E"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28.8994 4.75429C28.8371 4.5629 28.6679 4.43906 28.4685 4.43906L24.3924 4.42874L23.1072 0.615086C23.043 0.424637 22.8752 0.296577 22.6726 0.304552C22.4732 0.306897 22.3049 0.431674 22.2449 0.623529L21.0415 4.42874L16.9161 4.43906C16.7167 4.43906 16.5475 4.5629 16.4853 4.75382C16.4225 4.9452 16.4862 5.14644 16.647 5.26559L19.8752 7.66777L18.6091 11.7052C18.5486 11.897 18.6137 12.0969 18.7755 12.2151C18.8568 12.2742 18.9498 12.3042 19.0427 12.3042C19.1348 12.3042 19.2263 12.2751 19.3058 12.2174L22.7093 9.79694L26.0788 12.2155C26.2392 12.3338 26.4493 12.3333 26.6106 12.2151C26.7724 12.0973 26.8374 11.8975 26.7775 11.7056L25.5094 7.66777L28.7372 5.26606C28.898 5.14644 28.9617 4.9452 28.8994 4.75429Z"
                    fill="#FFC83E"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M45.3608 4.75429C45.2985 4.5629 45.1294 4.43906 44.9299 4.43906L40.8539 4.42874L39.5686 0.615086C39.5045 0.424637 39.3367 0.296577 39.134 0.304552C38.9346 0.306897 38.7663 0.431674 38.7064 0.623529L37.5029 4.42874L33.3776 4.43906C33.1782 4.43906 33.009 4.5629 32.9467 4.75382C32.8839 4.9452 32.9476 5.14644 33.1084 5.26559L36.3367 7.66777L35.0705 11.7052C35.01 11.897 35.0751 12.0969 35.2369 12.2151C35.3182 12.2742 35.4112 12.3042 35.5042 12.3042C35.5962 12.3042 35.6878 12.2751 35.7673 12.2174L39.1707 9.79694L42.5403 12.2155C42.7006 12.3338 42.9107 12.3333 43.072 12.2151C43.2338 12.0973 43.2989 11.8975 43.2389 11.7056L41.9708 7.66777L45.1986 5.26606C45.3594 5.14644 45.4231 4.9452 45.3608 4.75429Z"
                    fill="#FFC83E"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M61.8228 4.75429C61.7605 4.5629 61.5913 4.43906 61.3919 4.43906L57.3158 4.42874L56.0305 0.615086C55.9664 0.424637 55.7986 0.296577 55.5959 0.304552C55.3965 0.306897 55.2282 0.431674 55.1683 0.623529L53.9648 4.42874L49.8395 4.43906C49.6401 4.43906 49.4709 4.5629 49.4086 4.75382C49.3458 4.9452 49.4095 5.14644 49.5704 5.26559L52.7986 7.66777L51.5324 11.7052C51.472 11.897 51.537 12.0969 51.6988 12.2151C51.7801 12.2742 51.8731 12.3042 51.9661 12.3042C52.0581 12.3042 52.1497 12.2751 52.2292 12.2174L55.6326 9.79694L59.0022 12.2155C59.1625 12.3338 59.3726 12.3333 59.5339 12.2151C59.6957 12.0973 59.7608 11.8975 59.7008 11.7056L58.4328 7.66777L61.6605 5.26606C61.8214 5.14644 61.885 4.9452 61.8228 4.75429Z"
                    fill="#FFC83E"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M78.2842 4.75429C78.2219 4.5629 78.0527 4.43906 77.8533 4.43906L73.7772 4.42874L72.492 0.615086C72.4278 0.424637 72.26 0.296577 72.0573 0.304552C71.8579 0.306897 71.6897 0.431674 71.6297 0.623529L70.4263 4.42874L66.3009 4.43906C66.1015 4.43906 65.9323 4.5629 65.87 4.75382C65.8073 4.9452 65.8709 5.14644 66.0318 5.26559L69.26 7.66777L67.9938 11.7052C67.9334 11.897 67.9985 12.0969 68.1602 12.2151C68.2416 12.2742 68.3345 12.3042 68.4275 12.3042C68.5195 12.3042 68.6111 12.2751 68.6906 12.2174L72.0941 9.79694L75.4636 12.2155C75.624 12.3338 75.8341 12.3333 75.9954 12.2151C76.1571 12.0973 76.2222 11.8975 76.1622 11.7056L74.8942 7.66777L78.122 5.26606C78.2828 5.14644 78.3465 4.9452 78.2842 4.75429Z"
                    fill="#FFC83E"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_newJay">
                    <rect
                      width="78.3077"
                      height="12"
                      fill="white"
                      transform="translate(0 0.304199)"
                    ></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="col">
              <lottie-player
                class="animate__animated animate__zoomIn mx-auto"
                :src="downloadLottieJson"
                background="transparent"
                speed="1.5"
                style="
                  width: 25px;
                  display: inline-block;
                  position: relative;
                  top: 8px;margin-top: -7px;
                "
                loop
                autoplay
              ></lottie-player>
            </div>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "DownloadingAppFooter",
  data() {
    return {
      alreadyGetApp: false,
      showDownloadFooter: false,
      downloadLottieJson: JSON.stringify(
        require("@/assets/lotties/downloadLottie.json")
      ),
    };
  },
  mounted() {
    // this.alreadyGetApp = this.$cookies.get("alreadyGetApp") || false;
    setInterval(() => {
      this.verifyDownloadGoogleVisible();
    }, 100);
  },
  methods: {
    getApp: function () {
      this.$cookies.set("alreadyGetApp", true);
      this.alreadyGetApp = true;
      window.location.href="https://play.google.com/store/apps/details?id=me.chipdeals.mtn.momo.abonnement.canal&referrer=utm_source%3DMytiviplus landing page%26utm_medium%3DFooter download app%26utm_content%3DInstallez Mytivi+ " 
    },
    verifyDownloadGoogleVisible: function () {
      this.showDownloadFooter = this.$GlobalVars.showDownloadFooter;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.appLogo {
  width: 45px;
}
.smallText,
.smallText * {
  font-size: 0.95em !important;
}
.animate__animated {
  animation-duration: 0.4s !important;
}
.customedBtn>* {
  -padding-top: 0px !important;
}
</style>
