const { Commons } = require("../utils/commons");
const { RequestUtils } = require("../utils/requestUtils");

async function saveDeviceToken(deviseToken, subscriptionUuid) {
  console.log("saveDeviceTokenFunction", deviseToken, subscriptionUuid);
  await sendDeviceTokenSavingRequest(deviseToken, subscriptionUuid);
}

async function sendDeviceTokenSavingRequest(deviseToken, subscriptionUuid) {
  const response = await RequestUtils.sendRequestTo(
    Commons.serverBaseUrl() + "/deviceToken",
    { deviseToken, subscriptionUuid },
    "post"
  );
  console.log(response);
  return response;
}

exports.saveDeviceToken = saveDeviceToken;
