<template>
  <div class="form-group my-2">
    <label :for="name">{{ label || "" }}</label>
    <slot>
      <input
        :id="name"
        :name="name"
        :disabled="disabled"
        :type="fieldType"
        class="form-control mt-1"
        :placeholder="placeholder || ''"
        v-model="value"
      />
    </slot>
  </div>
</template>

<script>
export default {
  name: "InputField",
  props: {
    name: String,
    label: String,
    fieldType: String,
    placeholder: String,
    updateValue: Function,
    defaultValue: String,
    disabled: Boolean,
  },
  data() {
    return {
      value: this.defaultValue || "",
    };
  },
  watch: {
    value(newValue) {
      this.updateValue(newValue);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*::placeholder {
  color: rgb(214, 212, 221) !important;
}
</style>
