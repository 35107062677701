const { Commons } = require("../utils/commons");
const { RequestUtils } = require("../utils/requestUtils");

async function getSubscription(suscriptionUuid) {
  console.log("signupFunction", suscriptionUuid);
  const signupResponse = await sendRegistrationRequest(suscriptionUuid);
  const parsedResponse = parseSubscriptionResponse(signupResponse);
  console.log(parsedResponse);
  return parsedResponse;
}

async function sendRegistrationRequest(suscriptionUuid) {
  const response = await RequestUtils.sendRequestTo(
    Commons.serverBaseUrl() + "/subscription/" + suscriptionUuid,
    {},
    "get"
  );
  return response;
}

function parseSubscriptionResponse(subscriptionResponse) {
  console.log(subscriptionResponse);
  if (subscriptionResponse.status.code === 200) {
    return {
      success: true,
      message: "",
      subscription: {
        status: subscriptionResponse.response.transactionData.transactionStatus,
        statusMessageCode:
          subscriptionResponse.response.transactionData
            .transactionStatusMessageCode,
        message: statusCodeToStringMessage(
          subscriptionResponse.response.transactionData
            .transactionStatusMessageCode
        ),
      },
    };
  }
  if (subscriptionResponse.status.code === 404) {
    return {
      success: false,
      message:
        "Oh, 😧 désolé, une erreur s'est produite, nos développeurs s'en occupent, réessai en attendant",
      subscription: null,
    };
  }
}

exports.getSubscription = getSubscription;

function statusCodeToStringMessage(statusMessageCode) {
  const matchingResponse = Object.values(responsesStatusCodes).find(
    (responseStatusCode) => {
      if (responseStatusCode.messageCode == statusMessageCode) {
        console.log(responseStatusCode);
        return true;
      }
      return false;
    }
  );
  return matchingResponse
    ? matchingResponse.messageParsed
    : "Oh, 😧 désolé, une erreur s'est produite, nos développeurs s'en occupent, réessai en attendant";
}
const responsesStatusCodes = {
  success: {
    status: "success",
    message: "Successfully processed transaction",
    messageCode: 200,
    messageParsed: "Félicitation, Votre abonnement a été lancé",
  },

  dataAccepted: {
    status: "pending",
    message: "Data in validation",
    messageCode: 201,
    messageParsed: "",
  },
  pending: {
    status: "pending",
    message: "Transaction is pending",
    messageCode: 202,
    messageParsed: "",
  },
  dataValidated: {
    status: "pending",
    message: "Data are validated, payment server is working",
    messageCode: 203,
    messageParsed: "",
  },
  waitingUssdValidation: {
    status: "pending",
    message: "Waiting for ussd push validation of payment",
    messageCode: 204,
    messageParsed: "",
  },
  linkSeen: {
    status: "pending",
    message: "transaction has been seen",
    messageCode: 205,
    messageParsed: "",
  },
  acceptedBySender: {
    status: "pending",
    message: "transaction has been accepted and is being processed",
    messageCode: 206,
    messageParsed: "",
  },
  successPaymentPendingSubscription: {
    status: "pending",
    message: "Payment finished with success. User is being subscribe",
    messageCode: 230,
    messageParsed: "",
  },
  incorrectData: {
    status: "error",
    message: "Incorrect payment data enter in the request",
    messageCode: 400,
    messageParsed:
      "Votre numéro ou nom omo semble incorrecte. Corrigez et réessayer.",
  },
  paramsNotComplete: {
    status: "error",
    message: "Parameters not complete in payment info",
    messageCode: 401,
    messageParsed:
    "Votre numéro ou nom omo semble incorrecte. Corrigez et réessayer.",
  },
  badPhoneNumberPayment: {
    status: "error",
    message: "Payment PhoneNumber is not correct",
    messageCode: 402,
    messageParsed: "Votre numéro semble incorrecte. Corrigez et réessayer.",
  },
  ussdTimeout: {
    status: "error",
    message: "Timeout in USSD PUSH/ Cancel in USSD PUSH",
    messageCode: 404,
    messageParsed: "Vous n'avez pas confirmé le paiement",
  },
  rejectedByUser: {
    status: "error",
    message: "transaction has been rejected",
    messageCode: 405,
    messageParsed: "",
  },
  paymentPhoneNumberIsNotMomo: {
    status: "error",
    message: "Payment phoneNumber got is not for mobile money account",
    messageCode: 406,
    messageParsed:
      "Oups!. Le numéro que vous avez entré n'est pas attribué à un compte mobile money",
  },
  senderBalanceLow: {
    status: "error",
    message: "payer's balance is low",
    messageCode: 460,
    messageParsed: "Votre solde est insufisant pour faire l'abonnement.",
  },
  paymentError: {
    status: "error",
    message: "An error occured while paying",
    messageCode: 461,
    messageParsed:
      "Un erreur s'est produite, nos dévelopeurs vérifient de quoi il s'agit.",
  },
  noProcessor: {
    status: "error",
    message:
      "This kind of transaction is not supported yet, processor not found",
    messageCode: 462,
    messageParsed: "",
  },
  unknownServerError: {
    status: "error",
    message: "An unknow error occured on server",
    messageCode: 500,
    messageParsed: "",
  },
  transactionNotFound: {
    status: "error",
    message: "Transaction not Exists. Server error with payment service",
    messageCode: 500,
    messageParsed: "",
  },
  undefinedResponse: {
    status: "error",
    message: "An undefined error occured",
    messageCode: 500,
    messageParsed:
      "Un erreur s'est produite, nos dévelopeurs vérifient de quoi il s'agit.",
  },
  unregisteredPaymentServiceError: {
    status: "error",
    message: "An Error not registered occured on server. Call The dev Please ",
    messageCode: 501,
    messageParsed:
      "Un erreur s'est produite, nos dévelopeurs vérifient de quoi il s'agit.",
  },
  bouquetOrDurationError: {
    status: "error",
    message:
      "Bouquets or durations got for subscription are not found after payment is successful",
    messageCode: 503,
    messageParsed: "",
  },
  subscriptionPageError: {
    status: "error",
    message:
      "Error with subscription page on the server after payment is successful",
    messageCode: 504,
    messageParsed: "",
  },
  subscriptionInfoError: {
    status: "error",
    message: "Error with subscription info after payment is successful",
    messageCode: 505,
    messageParsed: "",
  },
  badPriceError: {
    status: "error",
    message: "Mismatching price on the dashboard after payment is successful",
    messageCode: 506,
    messageParsed: "",
  },
  unchangedPrice: {
    status: "error",
    message: "Dashboard price didn't changer after subscription",
    messageCode: 507,
    messageParsed: "",
  },
};

console.log(responsesStatusCodes);
