<template>
  <div class="card">
    <div class="x-close text-center" @click="onClickCancelBtn">✖</div>
    <div class="card-body mt-3">
      <h5 class="card-title" v-html="cardTitle || ''"></h5>
      <h6
        class="card-subtitle mb-2 text-muted"
        style="font-size: 11px"
        v-html="cardSubtitle || ''"
      ></h6>
      <div class="my-4">
        <slot></slot>
      </div>
      <div class="row">
        <div class="col-12 text-center ">
          <button
            class="me-2 ctaBtn  bold"
            :class="{ disabled: !activateNextButton }"
            @click="onClickContinueBtn()"
          >
            {{ buttonText || "Continuer" }}
          </button><br/>
          <span class="smallText" style='color:#f84730!important'> </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormCard",
  props: {
    cardTitle: String,
    cardSubtitle: String,
    onClickCancelBtn: Function,
    onClickContinueBtn: Function,
    buttonText: String,
    activateNextButton: Boolean,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  border: none;
  background: #fbfbfb;
  box-shadow: 0px 1.35743px 5.42972px rgba(117, 131, 142, 0.04),
    0px 10.8594px 16.2892px rgba(52, 60, 68, 0.12);
  border-radius: 5.42972px;
  position: relative;
}
.x-close {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 13px;
  top: 10px;
  transform: scale(1.5);
  font-size: 15px !important;
  cursor: pointer;
  transition-duration: 300ms;
  opacity:0.3;
}
.x-close:hover {
  transform: scale(2.3);
}
.smallText{
  font-size:10px;
}
</style>
