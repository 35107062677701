<template>
  <nav class="navbar navbar-light navbar-expand-lg">
    <div class="container">
      <router-link to="/" class="navbar-brand logo zoomHover"
        ><img src="/logo.png" /><span class="text-white">  </span>
      </router-link>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0"></ul>
        <ul class="navbar-nav ml-auto mb-2 mb-lg-0 d-auto d-md-inline-block">
          <li v-if="false"></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Header",
  components: {  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleSelect(path) {
      this.$router.push(path);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a,
a:hover {
  text-decoration: none;
}
.logo {
  color: var(--color1) !important;
  max-width: 50px;
  max-height: 100%;
  min-width: 50px;
  display: inline-block;
  height: auto;
  object-fit: contain;
}

.logo > img {
  width: 35px !important;
}
.logo > img {
margin-top:-5px
}

.navbar {
  background-color: transparent !important;
  position: absolute;
  z-index: 10;
}
</style>
