<template>
  <div class="home">
    <div>
      <div class="_3EwOR">
        <div class="_1_enf">
          <div></div>
          <div class="_2GUY_"></div>
          <div class="uiBuC _2pUB4 _2Bdqu">
            <div>
              <div class="_2mrb3">
                <div class="Bx81t">
                  <div class="_2xJZy">
                    <section class="_2TeGr">
                      <div class="_1rW8m">
                        <h1>
                          <img
                            loading="lazy"
                            src="@/assets/pictures/mytiviplus.png"
                            alt="Molotov, TV en streaming et TV direct."
                            width="200"
                          />
                        </h1>
                        <h2 class="_1BOnL" style="color: #ff004d; color: white">
                          Faites votre abonnement Canal+ moins cher
                        </h2>
                        <p class="_1UKLt">
                          Abonnez-vous depuis votre mobile à moindre coût.
                        </p>
                        <div class="text-center text-sm-start">
                          <a href="https://play.google.com/store/apps/details?id=me.chipdeals.mtn.momo.abonnement.canal&referrer=utm_source%3DMytiviplus landing page%26utm_medium%3DHero%26utm_content%3DM'abonner Tout de suite" 
                            id="firstCtaDownload"
                            class="_2Wbig ctaBtn position-relative"
                            > M'abonner Tout de suite
                            <span
                              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-gray2 colorRed1"
                            >
                              100+ déjà
                            </span>
                          </a>

                        </div>
                        <div class="_2q2q8">
                          <img
                            loading="lazy"
                            alt="Mockup"
                            class="_1z2pV"
                            src="@/assets/pictures/mytiviplusScreen.webp"
                          />
                          <div class="_1EGrD">
                            <img
                              class="_2lM0B"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUplu0TBdHgVsEKR7Qygv7RRqBz9sSA2pwZxgBCh8IARIbChTjAsdPSWBUGnIge-bOL_bOqhm4KhIDcG5n/jpg"
                              style=""
                              loading="lazy"
                            /><img
                              class="_2lM0B"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoULudScWZM0i-qWrcbNsZ4Y8GEohkSA2pwZxgBCh8IARIbChQQdCVd64qgk5qPaDSMb74y_7hXyBIDcG5n/jpg"
                              style=""
                              loading="lazy"
                            /><img
                              class="_2lM0B"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUxBGFZgGf2vhJzIjIMF9bMypi3ZsSA2pwZxgBCh8IARIbChSkJdYBXQMKvZT5voxk4uFJBKb6ehIDcG5n/jpg"
                              style=""
                              loading="lazy"
                            /><img
                              class="_2lM0B"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUoRQtAsoozu7U3pNm5xkQrJPNmvsSA2pwZxgBCh8IARIbChRT2FFrkbNXNxboQ6bteV-0XnEu5BIDcG5n/jpg"
                              style=""
                              loading="lazy"
                            /><img
                              class="_2lM0B"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUJfhuzq2D54uWK7Ep_OIMwPprAwkSA2pwZxgBCh8IARIbChREQYJ8Z3D8h2wqslhjvJPuuSBWixIDcG5n/jpg"
                              style=""
                              loading="lazy"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUlzUw3KL4XgLfJKqLf-m7kYnUWD0SA2pwZxgBCh8IARIbChQNoQfVUVTPyrr-kc7G2SyTaPXxgxIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Le génie des arbres"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUNDaehRdQ3TXcxkptBFArRIRGKz4SA2pwZxgBCh8IARIbChSQsWvUzwdcjPPjQI8MM9CmiL2yvxIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Black Water"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUzhuh87xcTvcyy5ZIDv8rPkUkeScSA2pwZxgBCh8IARIbChT_EIjLhWAEZIhtaypH6HLBZ78rJRIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Roches noires"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUVWa1WMu78iJI_rUiTWbdg9uCj8gSA2pwZxgBCh8IARIbChQQdCVd64qgk5qPaDSMb74y_7hXyBIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Plus belle la vie"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUmHxi5_NZ_ha6fjh4GMcxLweksDwSA2pwZxgBCh8IARIbChRu-JiZgm8Kj2R4YcqCHzqhL_f5jhIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Top chef"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoURh_xgQvhsqw38FfwA9R4kLXsQEISA2pwZxgBCh8IARIbChQlF6D9r1Wn5JL02N2nVYiCkkFQEBIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Au nom de la terre"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUKLMGbii2Scbv0TlPPgQqb5Mf_yQSA2pwZxgBCh8IARIbChRfNJkGsEA2QaxXsRqxaykROSvcRhIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Faites entrer l'accusé"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUn5YWW7GY-_N5a7SHzARV_7ylTdESA2pwZxgBCh8IARIbChTVlajKHkQXln2rfl7FIx5wcXNLiBIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Les Marseillais au Mexique"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUNorejVNoN8K4y9eJ8pBy0J0keboSA2pwZxgBCh8IARIbChQ6hfk5W6czItLwPnrSnB6t3s3ZehIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Le Live Toussaint"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUVNqpb34yNQpn6LwJ2bF_X7qHapASA2pwZxgBCh8IARIbChR7O-_pMWqtCz8UTUQUYJDDhVJBXBIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Starsky et Hutch"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoU7Xf8nRvr91xjKa_NfIJKAcdHWxQSA2pwZxgBCh8IARIbChQvscvjJhNB6BshHlJvponIT4OYZBIDcG5n/jpg"
                              alt="Gorge profonde : Quand le porno est sorti du ghetto"
                              width="113"
                              height="149"
                              style="top: 166.11px"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUEttvhOR0qSFOCYMuQj9BiSKc4Q4SA2pwZxgBCh8IARIbChRcoMM9HSE7lauv-WtGk6fJwnMWoBIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Medium"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoU556yXe5RgxkfJHePQkgwXNwJ8boSA2pwZxgBCh8IARIbChQlF6D9r1Wn5JL02N2nVYiCkkFQEBIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Le crime lui va si bien"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUgTR2syS2Ky7cxP5_zEzyspqiYocSA2pwZxgBCh8IARIbChQNoQfVUVTPyrr-kc7G2SyTaPXxgxIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="C dans l'air"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUTb_ELdZn-P83Ps7RUi8hI9qLqLMSA2pwZxgBCh8IARIbChS3pyDNxVqrU2Aqv6LaBSkegqKjxxIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="The Escort"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUg9jBhM9NEL67ag6lWoPq0VcXtAASA2pwZxgBCh8IARIbChQz_mtBleLNv6VKHR5ReQZcQfTHXRIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Seuls sur Terre"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUFfedzwASmHsRy3iLJH1p4jRgl1ASA2pwZxgBCh8IARIbChSvUujr6gn8AbDJHRaXG2CJn1-FhRIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Leonardo"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUfOEfTTczvOuZswEaRNazZPHBCXwSA2pwZxgBCh8IARIbChSB-CrMgZTIokPrOvwr4ijWsTt9gRIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Wheeler Dealers France"
                              width="113"
                              height="149"
                            /><img
                              class="_2gTrv"
                              src="https://fusion.molotov.tv/arts/i/155x204/Ch8SHQoUAS9kDdY3Xzc6mz9pWTo-xqimKKISA2pwZxgBCh8IARIbChRG0FjIMAIT3GjlJrZ5MyWB3xhcchIDcG5n/jpg"
                              style="top: 166.11px"
                              alt="Drôle de prof !"
                              width="113"
                              height="149"
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                    <section class=" mb-5">
                      <div class="text-center bg-white5 py-5">
                        <div class="container">
                          <h1 class="bold color1" style="font-size: 1.3em">
                            Fatigué de vous déplacer & perdre du temps pour
                            votre abonnement ?
                          </h1>
                          <div class="h5 col-10 mt-4 mx-auto">
                            <span class="">
                              Payez moins cher votre abonnement Canal+ depuis
                              votre mobile
                            </span>
                          </div>
                          <div class="col-10 mt-4" v-if="false">
                            Défile et découvre la solution
                          </div>
                        </div>
                      </div>
                    </section>

                    <section class="_31IJv _1G_ss _2He1v mytviplusLess">
                      <div class="_1rW8m _3dtwj">
                        <h3 class="JR19g">Désormais</h3>
                        <h2 class="_3OSNj normalTitle">
                          Payez moins cher votre bouquet
                        </h2>
                        <p>
                          Recevez une
                          <span class="bold">
                            réduction sur chaque abonnement
                          </span>
                          que vous faites. Payez moins que d'habitude pour vos
                          chaines.
                        </p>
                      </div>
                    </section>
                    <section class="_31IJv _2H0i8 _2He1v row">
                      <div
                        class="col-md-6 pt-md-5 mt-md-5 text-start order-md-last"
                      >
                        <h2 class="_1BOnL normalTitle text-start">
                          Faites votre abonnement depuis n'importe où.
                        </h2>
                        <p>
                          Que vous soyez en déplacement ou que vous soyez à la
                          maison,
                          <span class="bold"> payez votre bouquet </span> canal+
                          à moindre coût
                          <span class="bold"> depuis votre mobile </span>
                        </p>
                      </div>
                      <div
                        class="mx-auto col-11 col-md-5"
                        style="max-width: 400px"
                      >
                        <img
                          src="/images/mobileAppMockup.webp"
                          alt="mobile app mockup"
                          class="w-100"
                        />
                      </div>
                    </section>
                    <section class="_31IJv _2H0i8 _2He1v row">
                      <div class="col-md-6 pt-md-5 mt-md-5 text-start">
                        <h2 class="_1BOnL normalTitle text-start">
                          Tous les détails de chaque bouquet
                        </h2>
                        <p>
                          <span class="bold">
                            Évitez les abonnements à l'aveuglette.
                          </span>
                          Découvrez la liste des chaînes, le prix et les détails
                          de chaque bouquet.
                        </p>
                      </div>
                      <div
                        class="mx-auto col-11 col-md-5"
                        style="max-width: 400px"
                      >
                        <img
                          src="/images/bouquetDetailMockup.webp"
                          alt="mobile app mockup"
                          class="w-100"
                        />
                      </div>
                    </section>

                    <section class="_31IJv _2H0i8 _2He1v row">
                      <div class="col-md-6 text-start order-md-last">
                        <h2 class="_1BOnL normalTitle text-start">
                          Suivi de votre abonnement.
                        </h2>
                        <p>
                          <span class="bold">
                            D'un coup d'œil soyez au courant
                          </span>
                          du nombre de jours qu'il vous reste pour profiter de
                          votre abonnement, le bouquet activé et les infos de
                          votre décodeur.
                        </p>
                      </div>
                      <div
                        class="mx-auto col-9 col-md-5 mt-5 mt-md-3"
                        style="max-width: 400px"
                      >
                        <img
                          src="/images/subscriptionFollow.svg"
                          alt="mobile app mockup"
                          class="w-100"
                        />
                      </div>
                    </section>

                    <section class="_31IJv _1MFnM _2He1v pb-sm-5 mt-4">
                      <div class="_1rW8m">
                        <h2
                          class="_3OSNj normalTitle py-md-5"
                          style="max-width: 303px"
                        >
                          Votre programme télé disponible
                          <span class="text-black smallTitleIndice"
                            >(bientôt)</span
                          >
                        </h2>
                        <p class="text-black pb-md-5 mb-md-5">
                          Bénéficiez d'une liste de programmes à jour. Soyez au
                          courant de ce qui se passe sur vos chaines préférées
                          d'un seul coup d'œil.
                        </p>
                      </div>
                    </section>

                    <section class="_31IJv _1G_ss _2He1v">
                      <div class="_1rW8m _3dtwj">
                        <h2 class="_3OSNj normalTitle" style="max-width: 335px">
                          Tous les détails de vos programmes télé
                          <span class="text-black smallTitleIndice"
                            >(bientôt)</span
                          >
                        </h2>
                        <p>
                          Le nom de l'acteur, la bande-annonce, le résumé...
                          Trouvez tous les détails dont vous avez besoin pour
                          vous décider sur l'émission que vous voulez regarder
                        </p>
                      </div>
                    </section>

                    <section class="_31IJv _3G7-E _2He1v">
                      <div class="_1rW8m _3dtwj">
                        <h2 class="_3OSNj normalTitle" style="max-width: 335px">
                          Impossible de manquer votre programme préféré
                          <span class="text-black smallTitleIndice"
                            >(bientôt)</span
                          >
                        </h2>
                        <p class="text-black pb-5">
                          Créez des alertes pour être notifié lorsqu'une de vos
                          émissions préférées est sur le point de débuter
                        </p>
                        <div
                          v-if="false"
                          class="notification row cardSession bg-white3 py-2 mt-5"
                        >
                          <div class="pictureTopRight p-0">
                            <img
                              class="fill_pic"
                              src="@/assets/pictures/green_check.png"
                              alt="checked"
                            />
                          </div>
                          <div class="col-4 p-0">
                            <slot name="picture">
                              <div class="my-auto">
                                <img
                                  src="https://fusion.molotov.tv/arts/i/446x588/Ch8SHQoURNfi06tZYlpBj_ATOYSVg9PHByMSA2pwZxgBCh8IARIbChQNoQfVUVTPyrr-kc7G2SyTaPXxgxIDcG5n/jpg"
                                  alt=""
                                  style="width: 60%"
                                  class="ms-4 rounded"
                                />
                                <lottie-player
                                  v-if="false"
                                  src="/notifAnimation.json"
                                  background="transparent"
                                  speed="1.5"
                                  style="width: 100%; transform: scale(2.5)"
                                  loop
                                  autoplay
                                ></lottie-player>
                              </div>
                            </slot>
                          </div>
                          <div class="col-8 d-inline-flex">
                            <div class="my-auto row">
                              <div class="col-12 ps-0 smallText">
                                Votre émission
                                <span class="bold">L'aventurier du goût</span>
                                débute dans 10 minutes.
                              </div>
                              <div class="col-10 ps-0" v-if="false">
                                <span class="bold">Perte évitée:</span>
                                {{ transaction.saving }}F
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section class="yLGwO mb-5">
                      <div class="_1rW8m">
                        <h2 class="_3Bb-s normalTitle" style="max-width: 300px">
                          Actuellement à la télé
                          <span class="text-black smallTitleIndice"
                            >(bientôt)</span
                          >
                        </h2>
                        <div>
                          <div class="_3VtJu">
                            <div class="_2Uz-A _3cGlb _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a class="cCUjI" href="/fr_fr/p/458-46/tfou"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoUeEnoAg4gW1OtoEbpC4e6YvJBC54SA2pwZxgBCh8IARIbChTgzuFgqrFVPOFlbGOO6vEqUgl5HxIDcG5n/jpg"
                                      alt="TF1 - TFou" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 12%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="_2Uz-A _3cGlb _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a
                                    class="cCUjI"
                                    href="/fr_fr/p/21192-19/telematin"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoUMe8o-U1fhUWpxT0UV_gJlF3rG_kSA2pwZxgBCh8IARIbChQlF6D9r1Wn5JL02N2nVYiCkkFQEBIDcG5n/jpg"
                                      alt="France 2 - Télématin" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 16%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="_2Uz-A _3cGlb _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a
                                    class="cCUjI"
                                    href="/fr_fr/p/69381-49/taffy"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoU5jpIMkrgWp2xLMDXSjW7HVCBMugSA2pwZxgBCh8IARIbChQQdCVd64qgk5qPaDSMb74y_7hXyBIDcG5n/jpg"
                                      alt="France 3 - Taffy" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 63%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="_2Uz-A _3cGlb _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a
                                    class="cCUjI"
                                    href="/fr_fr/p/7337-42/petit-ours-brun"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoUo7L0UrrCcdZzzgVMULEXNsMv5n4SA2pwZxgBCh8IARIbChQNoQfVUVTPyrr-kc7G2SyTaPXxgxIDcG5n/jpg"
                                      alt="France 5 - Petit Ours Brun" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 78%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="_2Uz-A _3cGlb _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a
                                    class="cCUjI"
                                    href="/fr_fr/p/746-45/m6-music-horaires-sous-reserve"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoUUwXNb1lN79_khiIAzvgFacOEI_8SA2pwZxgBCh8IARIbChRu-JiZgm8Kj2R4YcqCHzqhL_f5jhIDcG5n/jpg"
                                      alt="M6 - M6 Music (horaire sous réserves)" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 89%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="_2Uz-A _3cGlb _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a
                                    class="cCUjI"
                                    href="/fr_fr/p/347294-34/yukon--un-reve-blanc"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoUhri2caVlWxi6CbNPSXSXEbez5A8SA2pwZxgBCh8IARIbChQJrc6e8V2RulpDTYVy9d90XJ-C6xIDcG5n/jpg"
                                      alt="Arte - Yukon : Un rêve blanc" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 86%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="_2Uz-A _3cGlb _1y3Dc _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a
                                    class="cCUjI"
                                    href="/fr_fr/p/964-62/gym-direct"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoUmNAhvOWKdYTGUXBncFGnANlDB84SA2pwZxgBCh8IARIbChTkr1U_Y1pf3Ku7YOthak4jat-euxIDcG5n/jpg"
                                      alt="C8 - Gym direct" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 44%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="_2Uz-A _3cGlb _1y3Dc _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a
                                    class="cCUjI"
                                    href="/fr_fr/p/826-51/wake-up"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoUo0S5e0NN0qtq-gDxmkiTqlHtBpkSA2pwZxgBCh8IARIbChTVlajKHkQXln2rfl7FIx5wcXNLiBIDcG5n/jpg"
                                      alt="W9 - Wake Up" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 73%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="_2Uz-A _3cGlb _1y3Dc _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a
                                    class="cCUjI"
                                    href="/fr_fr/p/28530-36/programmes-de-la-nuit-passage-a-lheure-dete"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoUF1eslaZYCnyyrFymnIEhQPDvOAwSA2pwZxgBCh8IARIbChRyYCRR3tr8FBrPJaicl5v0twOMABIDcG5n/jpg"
                                      alt="TMC - Programmes de la nuit" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 99%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="_2Uz-A _3cGlb _1y3Dc _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a
                                    class="cCUjI"
                                    href="/fr_fr/p/460-40/petits-plats-en-equilibre"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoUtBtP4PK9v2fhzd1c9fkwrGvRWK0SA2pwZxgBCh8IARIbChSBH9Q1MTBGlnroit916ti1HFIXKBIDcG5n/jpg"
                                      alt="TFX - Petits plats en équilibre" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 63%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="_2Uz-A _3cGlb _1y3Dc _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a
                                    class="cCUjI"
                                    href="/fr_fr/p/111-26/tele-achat"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoUdd_Tj0YtiDQnec4D-2-gDNbcL1sSA2pwZxgBCh8IARIbChREQYJ8Z3D8h2wqslhjvJPuuSBWixIDcG5n/jpg"
                                      alt="NRJ 12 - Télé-achat" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 5%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="_2Uz-A _3cGlb _1y3Dc _3iOhZ">
                              <div class="_22b4i">
                                <div>
                                  <a
                                    class="cCUjI"
                                    href="/fr_fr/p/290303-8/sens-public"
                                    ><img
                                      class="_1rs9S"
                                      src="https://fusion.molotov.tv/arts/i/224x294/Ch8SHQoULmuDvbUjlQkr804B9s36oBH8sA8SA2pwZxgBCh8IARIbChQfElb_QLDzUuxtCzO5X5gk6FBhoBIDcG5n/jpg"
                                      alt="LCP Public Sénat - Sens public" />
                                    <div class="_3pDVP _1_sc7">
                                      <div class="_2PFr7"></div>
                                      <div
                                        class="_3KO6V"
                                        style="width: 48%"
                                      ></div></div
                                  ></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center my-2 mb-4">
                        <a class="ctaBtn" href="https://play.google.com/store/apps/details?id=me.chipdeals.mtn.momo.abonnement.canal&referrer=utm_source%3DMytiviplus landing page%26utm_medium%3DPage bottom%26utm_content%3DM'abonner moins cher maintenant" >
                          M'abonner moins cher maintenant
                        </a>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: {},
  data() {},
  mounted() {
    this.observeElementVisibility(
      "#firstCtaDownload",
      () => {
        this.$GlobalVars.showDownloadFooter = false;
      },
      () => {
        this.$GlobalVars.showDownloadFooter = true;
      }
    );
  },

  methods: {
    observeElementVisibility(
      elementSelector,
      onVisible = () => {},
      onUnvisible = () => {}
    ) {
      const element = this.$el.querySelector(elementSelector);
      var observer = new IntersectionObserver(
        function (entries) {
          if (entries[0].isIntersecting === true) {
            onVisible();
          } else {
            onUnvisible();
          }
        },
        { threshold: [1] }
      );
      observer.observe(element);
    },
  },
});
</script>

<style scoped>
._1UKLt {
  color: #aab5c6 !important;
}
._2TeGr {
  position: relative;
  overflow: hidden;
  background: #212121;
  background: linear-gradient(180deg, #212121, #212121 60%, #627796);
}
._1MFnM._2He1v {
  background-color: var(--colorGray2);
}
._3G7-E {
  background-color: var(--colorGray2);
  margin-left: -12%;
}
._3mjsd {
  background-color: var(--colorGray2);
}
._3mjsd:after,
._3mjsd:before {
  background: var(--colorGray2);
}
.cqcXZ._2He1v {
  background-color: var(--colorGray2);
}
.cqcXZ:after,
.cqcXZ:before {
  background-color: var(--colorGray2);
}
._3DSQZ {
  background: var(--color3);
}
.yLGwO:before {
  content: "";
  position: absolute;
  bottom: 99.9%;
  background: #fff;
  height: 80px;
  width: 60.1%;
  border-top-left-radius: 149% 50%;
}
.yLGwO::after {
  content: "";
  position: absolute;
  bottom: 99.9%;
  background: #fff;
  height: 80px;
  left: 60%;
  width: 40%;
  border-top-right-radius: 149% 80%;
}
.normalTitle {
  color: #212121 !important;
}
.text-black {
  color: #212121 !important;
}
.smallTitleIndice {
  font-size: 28px;
}

.lol {
  background-color: rgba(255, 255, 255, 0.5);
  background-filter: blur(10px);
}
</style>
